<template>
  <div class="ClientDocumentAskingProfile">

    <!-- MODIFY DOCUMENT MODAL -->
    <CModal
      :show.sync="isDocumentModifying"
      :no-close-on-backdrop="true"
      title="Modifier votre document"
      size="lg"
      color="dark"
    >

      <CForm class="mt-2">
        <em> Votre document actuel : {{isModifiedDocumentName}}</em><br>
        <strong v-if="!$v.modifyDocumentFile.$invalid"> Votre nouveau document : {{modifyDocumentFile.name}}</strong>
        <b-form-group class="mt-3">
          <b-form-file
            class="text-truncate"
            browse-text='Parcourir'
            required
            v-model="modifyDocumentFile"
            id="modify-logo-file-input"
            placeholder="Sélectionner votre fichier"
            drop-placeholder="Déposez votre fichier ici"
            @input="$v.modifyDocumentFile.$touch()"
            :state="$v.modifyDocumentFile.$dirty ? !$v.modifyDocumentFile.$error : null"
          ></b-form-file>
          <div class="form-error" v-if="!$v.modifyDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
          <div class="form-error" v-if="!$v.modifyDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
          <div class="form-error" v-if="!$v.modifyDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
          <div class="form-error" v-if="!$v.modifyDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
        </b-form-group>
        <CRow class="mt-4">
          <CCol class="text-center">
            <CButton
              :color="modifyDocumentButtonStyle"
              shape="pill" block class="px-4"
              :disabled="$v.modifyDocumentFile.$invalid || modifyDocumentInProcess"
              @click='modifyDocument'>
              <CSpinner size="sm" label="update document spinner" v-if="modifyDocumentInProcess"></CSpinner>
              {{ modifyDocumentButtonText }}
            </CButton>
          </CCol>
        </CRow>
      </CForm>

      <template #footer>
        <CButton @click="isDocumentModifying = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <!-- REMOVE DOCUMENT MODAL -->
    <CModal
      :show.sync="isDocumentDeleting"
      :no-close-on-backdrop="true"
      title="Supprimer votre document"
      size="lg"
      color="dark"
    >
      <CRow>
        <CCol>
          Attention, si vous supprimez ce document, aucun collaborateur de votre cabinet ne pourra plus y accéder.
        </CCol>
      </CRow>


      <CRow class="mt-4">
        <CCol class="text-center">
          <CButton
            :color="deleteDocumentButtonStyle"
            shape="pill" block class="px-4"
            :disabled="deleteDocumentInProcess"
            @click='deleteDocument'>
            <CSpinner size="sm" label="update document spinner" v-if="deleteDocumentInProcess"></CSpinner>
            {{ deleteDocumentButtonText }}
          </CButton>
        </CCol>
      </CRow>

      <template #footer>
        <CButton @click="isDocumentDeleting = false" color="dark">Annuler</CButton>
      </template>
    </CModal>


    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CRow class="mb-3">
      <CCol>
        <h1> Demande de document - {{documentAsking.name}} </h1>
      </CCol>
    </CRow>

    <CCard class="mt-3">
      <CCardBody>
        <CRow class="mb-2">
          <CCol>
            <strong style="font-size: 1.3em;"> Statut de la demande  :<br>
              <span v-if="documentAsking.state == 'CREATED'" class="text-primary" style="vertical-align: middle;">
                <CIcon name="cil-pencil" size="lg"/>
                <span class="ml-1">Documents à charger</span>
              </span>
              <span v-if="documentAsking.state == 'TRANSFERED'" class="text-dark" style="vertical-align: middle;">
                <CIcon name="cil-clock" size="lg"/>
                <span class="ml-1">En cours de récupération et de validation</span>
              </span>
              <span v-if="documentAsking.state == 'REFUSED'" class="text-danger" style="vertical-align: middle;">
                <CIcon name="cil-x-circle" size="lg"/>
                <span class="ml-1">Documents incomplets, veuillez modifier vos documents</span>
              </span>
              <span v-if="documentAsking.state == 'VALIDATED'" class="text-success" style="vertical-align: middle;">
                <CIcon name="cil-check-circle" size="lg"/>
                <span class="ml-1">Documents récupérés et validés</span>
              </span>
            </strong>
          </CCol>
        </CRow>

        <CRow v-if="documentAsking.state == 'REFUSED'">
          <CCol>
            <em class="text-danger"> {{documentAsking.refused_message}} </em>
          </CCol>
        </CRow>

        <CRow class="mt-3">
          <CCol>
            <span>Mission : </span>
            <strong> {{ documentAsking.mission.name }} </strong>
          </CCol>
        </CRow>


        <CRow>
          <CCol>
            <span>Description :
              <em v-if="documentAsking.description">
                {{ documentAsking.description }}
              </em>
              <em v-else>
                Aucune
              </em>
             </span>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

    <CCard>
      <CCardHeader>
        <strong>Documents</strong>
      </CCardHeader>
      <CCardBody>
        <strong> Ajouter un document </strong> <span v-if="!$v.createDocumentFile.$invalid"> / <em>Votre document : {{createDocumentFile.name}}</em></span>
        <CDataTable
          addTableClasses="no-border-table no-margin-bottom-table"
          :header="false"
          headVariant="light"
          :items="[createDocumentFile]"
          :fields="createDocumentRenderFields"
        >
        <template #file="">
          <td>
            <b-form-file
              class="text-truncate"
              browse-text='Parcourir'
              required
              v-model="createDocumentFile"
              id="create-file-file-input"
              placeholder="Sélectionner votre fichier"
              drop-placeholder="Déposez votre fichier ici"
              @input="$v.createDocumentFile.$touch()"
              :state="$v.createDocumentFile.$dirty ? !$v.createDocumentFile.$error : null"
            ></b-form-file>
            <div class="form-error" v-if="!$v.createDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
            <div class="form-error" v-if="!$v.createDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
            <div class="form-error" v-if="!$v.createDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
            <div class="form-error" v-if="!$v.createDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
          </td>
        </template>
        <template #actions="">
          <td>
            <CButton
              v-if="!isDocumentAskingValidated"
              :color="createDocumentButtonStyle"
              square
              block
              :disabled="$v.createDocumentFile.$invalid || createDocumentInProcess"
              @click="createDocument"
            >
            {{createDocumentButtonText}}
            </CButton>

            <CButton
              v-else
              color="outline-dark"
              square
              block
              :disabled="true"
            >
              Demande validée
            </CButton>

          </td>
        </template>
        </CDataTable>

        <CDataTable
          :items="documentsRender"
          :fields="documentsRenderFields"
          :noItemsView='{ noResults: "Aucun document", noItems: "Aucun document" }'
        >
        <!--@row-clicked="goToMissionPage"-->
        <template #file_size="{item}">
          <td>
            {{ sizeFormatter(item.file_size) }}
          </td>
        </template>

        <template #actions="{item}">
          <td class="text-right">
            <CButton
              class="ml-2"
              square
              size="sm"
              @click="downloadOwnDocument(item.id, item.file_name)">
              <CIcon class="text-dark" name="cil-cloud-download"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              size="sm"
              :disabled="isDocumentAskingValidated"
              @click="openModifyDocumentModal(item)">
              <CIcon class="text-primary" name="cil-pencil"/>
            </CButton>
            <CButton
              class="ml-2"
              square
              size="sm"
              :disabled="isDocumentAskingValidated"
              @click="openDeleteDocumentModal(item)">
              <CIcon class="text-danger" name="cil-trash"/>
            </CButton>
          </td>
        </template>

        <template #no-items-view>
          <CRow>
            <CCol class="text-center">
              Aucun document chargé
            </CCol>
          </CRow>
        </template>

        </CDataTable>
      </CCardBody>
    </CCard>


  </div>
</template>

<script>
import { APIClientConnected } from '@/api/APIClientConnected'
import renderMixins from '@/mixins/renderMixins'

import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength} from 'vuelidate/lib/validators'
import { file_size_validation, file_extension_validation } from '@/validators/validators'
import { fileOperations } from '@/mixins/utilMixins'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiClientConnected = new APIClientConnected()

export default {
  name: 'ClientDocumentAskingProfile',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    renderMixins,
    fileOperations
  ],
  data: function () {
    return {

      documentAsking: {
        name: '',
        description: '',
        state: 'CREATED',
        mission: {
          name: ''
        }
      },
      isDocumentAskingLoading: false,

      // ------------- DOCUMENTS -----------
      documents: [],
      documentsRender: [],
      documentsRenderFields: [
        { key: "file_name", label: "Nom", tdClass: 'ui-helper-center', sortable: true},
        { key: "file_size", label: "Taille", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'ui-helper-center', sortable: true},
      ],
      isAllDocumentsLoading: false,

      createDocumentFile: [],
      createDocumentRenderFields: [
        { key: "file", label: "", tdClass: 'ui-helper-center', sortable: true},
        { key: "actions", label: "", tdClass: 'ui-helper-center', sortable: true},
      ],
      createDocumentButtonText: 'Ajouter',
      createDocumentButtonStyle: 'outline-dark',
      createDocumentInProcess: false,

      isModifiedDocumentID: '',
      isModifiedDocumentName: '',
      isDocumentModifying: false,

      modifyDocumentFile: [],
      modifyDocumentButtonText: 'Modifier',
      modifyDocumentButtonStyle: 'outline-dark',
      modifyDocumentInProcess: false,

      isDeletedDocumentID: '',
      isDocumentDeleting: false,

      deleteDocumentButtonText: 'Supprimer',
      deleteDocumentButtonStyle: 'outline-danger',
      deleteDocumentInProcess: false,

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isDocumentAskingLoading || this.isAllDocumentsLoading) {
        return true
      }
      return false
    },
    isDocumentAskingValidated () {
      if (this.documentAsking.state == 'VALIDATED') {
        return true
      }
      return false
    }
  },
  created: function() {
    this.getClientDocumentAsking()
    this.getClientAllDocuments()
  },
  watch: {
    documents: function (newDocuments) {
      if (newDocuments.length == 0) {
        this.documentsRender = []
      }
      else {
        var final_array = []

        for (var i = 0; i < newDocuments.length; i++) {
          final_array.push(
            {
              'id': newDocuments[i].id,
              'file_name': newDocuments[i].file_name,
              'file_size': newDocuments[i].file_size,
            }
          )
        }
        this.documentsRender = final_array
      }
    }
  },
  validations: {

    // DOCUMENT
    createDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },

    modifyDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },

  },
  methods: {
    // ----------------- GETTERS ---------------------------------------
    getClientDocumentAsking () {
      this.isDocumentAskingLoading = true
      apiClientConnected.connectedClientGetDocumentAsking(this.token, this.$route.params.document_asking_pk)
      .then((result) => {
        this.documentAsking = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isDocumentAskingLoading = false
      })
    },

    getClientAllDocuments () {
      this.isAllDocumentsLoading = true
      apiClientConnected.connectedClientGetAllDocuments(this.token, this.$route.params.document_asking_pk)
      .then((result) => {
        this.documents = result.data
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isAllDocumentsLoading = false
      })
    },

    // ----------------- CREATE DOCUMENT---------------------------------
    loadCreateDocument(files) {
      this.createDocumentFile = files[0]
    },
    createDocument () {
      this.createDocumentButtonText = "Envoi en cours"
      this.createDocumentButtonStyle = "secondary"
      this.createDocumentInProcess = true
      apiClientConnected.createDocument(
        this.token, this.$route.params.document_asking_pk, this.createDocumentFile)
        .then(() => {
          this.createDocumentFile = []
          this.getClientAllDocuments()
          if (this.documentAsking.state === 'CREATED' || this.documentAsking.state === 'REFUSED') {
            this.getClientDocumentAsking()
          }
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.createDocumentButtonText = "Ajouter"
          this.createDocumentButtonStyle = "outline-dark"
          this.createDocumentInProcess = false
        })
    },

    openModifyDocumentModal(item) {
      this.isModifiedDocumentID = item.id
      this.isModifiedDocumentName = item.file_name
      this.isDocumentModifying = true
    },
    loadModifyDocument(files) {
      this.modifyDocumentFile = files[0]
    },
    modifyDocument () {
      this.modifyDocumentButtonText = "Modification en cours"
      this.modifyDocumentButtonStyle = "secondary"
      this.modifyDocumentInProcess = true
      apiClientConnected.modifyDocument(this.token, this.isModifiedDocumentID, this.modifyDocumentFile)
        .then(() => {
          this.modifyDocumentID = ''
          this.isModifiedDocumentName = ''
          this.modifyDocumentFile = []
          this.getClientAllDocuments()
          if (this.documentAsking.state === 'CREATED' || this.documentAsking.state === 'REFUSED') {
            this.getClientDocumentAsking()
          }
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isDocumentModifying = false
          this.modifyDocumentButtonText = "Modifier"
          this.modifyDocumentButtonStyle = "outline-dark"
          this.modifyDocumentInProcess = false
        })
    },

    openDeleteDocumentModal(item) {
      this.isDeletedDocumentID = item.id
      this.isDocumentDeleting = true
    },
    deleteDocument () {
      this.deleteDocumentButtonText = "Suppression en cours"
      this.deleteDocumentButtonStyle = "secondary"
      this.deleteDocumentInProcess = true
      apiClientConnected.deleteDocument(this.token, this.isDeletedDocumentID)
        .then(() => {
          this.isDeletedDocumentID = ''
          this.getClientAllDocuments()
        })
        .catch(() => {
          this.$store.commit('openGlobalErrorModal')
        })
        .finally(() => {
          this.isDocumentDeleting = false
          this.deleteDocumentButtonText = "Supprimer"
          this.deleteDocumentButtonStyle = "outline-danger"
          this.deleteDocumentInProcess = false
        })
    },

    downloadOwnDocument (document_id, document_name) {
      apiClientConnected.connectedClientDownloadOwnDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$store.commit('openGlobalErrorModal')
        })
    },
  }
}
</script>
<style>
.no-border-table td {
  border-top: None;
}

.no-border-table th {
  border-top: None;
  border-bottom: None !important;
}


.no-margin-bottom-table {
  margin-bottom: 0px !important;
}
</style>
